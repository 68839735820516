.section-equipe{
    background-color: #F5F5F5;
    padding-bottom: 30px;
    padding-top: 35px;

    @media(max-width: 991px){
        padding-top: 0;
    }

    .card-equipe{
        margin-bottom: 30px;
        border: 1px #E6E6E6 solid;
        position: relative;
        transition: all 0.5s ease;

        &:hover{
            .box-foto-equipe{
                .foto-equipe{
                    transform: scale(1.02);
                }
            }
        }

        .box-foto-equipe{
            padding: 1rem;
            background-color: #fff;

            .foto-equipe{
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 16.69rem;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }

        .conteudo-equipe{
            padding: 30px;
            background-color: #fff;

            .barra-cinza{
                border-top: 1px #E6E6E6 solid;
            }

            .titulo-equipe{
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111431;
                transition: all 0.5s ease;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                will-change: transform;

                @media(max-width: 991px){
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .ver-mais-equipe{
        margin-top: 4.9rem;
        text-align: center;
        z-index: 1;

        a{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            border: 1px #111431 solid;
            border-radius: 6px;
            padding: 0.85rem 2.2rem;
            transition: all 0.5s ease;

            img{
                width: 21px;
                margin-left: 5px;
                transition: all 0.5s ease;
            }

            &:hover, &:focus{
                transition: all 0.5s ease;
                padding-right: calc(2.2rem - 5px);

                img{
                    margin-left: 10px;
                    transition: all 0.5s ease;
                }
            }
        }
    }
}
