.header{
    .nivel-1{
        border-bottom: 1px solid rgba(51, 51, 51, 0.06);
        background-color: #fff;
        padding: 12px 0px;
        font-weight: normal;
        font-size: 13px;
        line-height: 22px;
        color: #b8b8b8;
        font-family: "Rubik", sans-serif;

        img{
            width: 15px;
        }

        .box-telefones{
            display: flex;
            align-items: center;
            justify-content: left;

            @media(max-width: 1199px){
                justify-content: center;
            }

            .telefones{
                text-align: left;

                @media(max-width: 1199px){
                    margin-bottom: 5px;
                    text-align: center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 0rem 0.5rem;
                }

                a{
                    color: #c4c4c4;
                    will-change: transform;
                    transition: all 0.5s ease;

                    &:hover, &:focus{
                        text-decoration: none !important;
                        transition: all 0.5s ease;
                    }
                }
            }
        }

        .atendimento{
            display: flex;
            align-items: center;
            justify-content: flex-end;


            @media(max-width: 500px){
                display: block;
                text-align: center;
            }

            @media(min-width: 501px) and (max-width: 1199px){
                justify-content: center;
                text-align: center;
            }
        }

        .barra-vertical{
            width: 1px;
            margin-left: 10px;

            @media(max-width: 540px){
                display: none !important;
            }
        }

      .redes-sociais-level1{
        @media(max-width: 540px){
            margin-top: 5px;
        }

        .rede-social-nivel-1{
            font-size: 15px;
            margin-left: 10px;
            transition: all 0.5s ease;
            will-change: transform;

            &:hover, &:focus{
                transition: all 0.5s ease;
            }
        }
      }
    }

    .nivel-2{
        background-color: #fff;
        // padding: 1.5rem 0rem;

        @media(max-width: 991px){
            padding: 0.5rem 0;
        }

        .logo-topo{
            margin: 10px 0px;
            max-width: 296px;
            will-change: transform;
            transition: all 0.5s ease;
            max-height: 88px;

            @media(max-width: 991px){
                max-width: 180px;
            }

            @media(min-width:992px) and (max-width: 1200px){
                max-width: 230px;
            }

            &:hover, &:focus{
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }

        .img-buscar{
            will-change: transform;
            transition: all 0.5s ease;

            &:hover, &:focus{
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }
    }

    .btn-xs-botao{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .nav-item{
        padding-left: 50px;

        @media(max-width: 991px){
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-left: 0px;
        }

        .nav-link{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &::before{
                content: "";
                position: absolute;
                bottom: 0px;
                width: 0px;
                opacity: 0;
                border-radius: 6px;
                height: 3px;
                left: 50%;
                transition: all 0.5s ease;
            }

            &:hover, &:focus{
                transition: all 0.5s ease;

                &::before{
                    width: 100%;
                    left: 0;
                    transition: all 0.5s ease;
                    opacity: 1;
                }
            }
        }

        .rota-ativa{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &:hover, &:focus{
                text-decoration: none !important;
            }

            &::before{
                content: "";
                position: absolute;
                bottom: 0px;
                width: 100%;
                opacity: 1;
                border-radius: 6px;
                height: 3px;
                transition: all 0.5s ease;
            }
        }
    }

    .aux-padding-categorias{
        padding-right: 15px;
        padding-left: 15px;

        @media(max-width: 560px){
            padding: 0px;
        }
    }

}

.header-2{

    .btn-xs-botao{
        margin-left: 1.5rem;
        margin-right: 1.5rem;
    }

    .nivel-2{
        background-color: #fff;

        @media(max-width: 991px){
            padding: 1rem 0rem;
        }

        .logo-topo{
            margin: 10px 0px;
            max-width: 296px;
            will-change: transform;
            transition: all 0.5s ease;
            max-height: 135px;

            @media(max-width: 991px){
                max-width: 180px;
            }

            @media(min-width:992px) and (max-width: 1200px){
                max-width: 230px;
            }

            &:hover, &:focus{
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }

        .img-buscar{
            will-change: transform;
            transition: all 0.5s ease;

            &:hover, &:focus{
                transition: all 0.5s ease;
                transform: scale(1.01);
            }
        }
    }

    .nav-item{
        padding-left: 50px;

        @media(max-width: 991px){
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            padding-left: 0px;
        }

        .nav-link{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &::before{
                content: "";
                position: absolute;
                bottom: 0px;
                width: 0px;
                opacity: 0;
                border-radius: 6px;
                height: 3px;
                left: 50%;
                transition: all 0.5s ease;
            }

            &:hover, &:focus{
                transition: all 0.5s ease;

                &::before{
                    width: 100%;
                    left: 0;
                    transition: all 0.5s ease;
                    opacity: 1;
                }
            }
        }

        .rota-ativa{
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            transition: all 0.5s ease;
            padding-bottom: 0.25rem !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
            position: relative;

            &:hover, &:focus{
                text-decoration: none !important;
            }

            &::before{
                content: "";
                position: absolute;
                bottom: 0px;
                width: 100%;
                opacity: 1;
                border-radius: 6px;
                height: 3px;
                transition: all 0.5s ease;
            }
        }
    }

    .aux-padding-categorias{
        padding-right: 15px;
        padding-left: 15px;

        @media(max-width: 560px){
            padding: 0px;
        }
    }

}

.btn-padrao{
    transition: all 0.5s ease;
    background-color: #fff;
    padding-top: 0;
    padding-bottom: 0;

    i{
        font-size: 20px !important;
    }
}

.transition-navbar{
    transition: all 0.5s ease;
}

.et_pb_menu__search-container, .et_pb_menu .et_pb_menu__search-container{
    @media(max-width: 991px){
        position: relative;
    }
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    opacity: 0;
    visibility: hidden;
    display: flex;
    transition: all 0.5s ease;
}

.et_pb_menu__search{
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: stretch;
    -ms-flex-pack: stretch;
    justify-content: stretch;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    form{
        width: 100%;
    }
}

.formbuscador{
    display: flex;
    align-items: center;

    i{
        cursor: pointer;
        transition: all 0.5s ease;

        &:hover, &:focus{
            transition: all 0.5s ease;
        }
    }
}

.padding-buscador{
    padding-top: 0px;
    padding-left: 31rem;

    @media(max-width: 991px){
        padding-left: 0px;
    }

    @media(min-width: 992px) and (max-width: 1199px){
        padding-left: 8rem;
    }

    @media(min-width: 1200px) and (max-width: 1599px){
        padding-left: 18rem;
    }
}

.magnifyColor{
    font-size: 30px;
}

.faTimes{
    font-size: 20px;
    margin-right: 10px;
}
