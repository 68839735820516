@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/regular";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/solid";
//@import "../../../node_modules/@fortawesome/fontawesome-free/scss/brands";

// Variables
@import "variables";

// Bootstrap
@import "../../../node_modules/bootstrap/scss/bootstrap";

//Modulos externos
//tiny slider
@import "../../../node_modules/tiny-slider/src/tiny-slider";
//Lightgalery
@import "../../../node_modules/lightgallery/src/sass/lightgallery";
//select2
@import "../../../node_modules/select2/dist/css/select2.css";
@import "../../../node_modules/select2-bootstrap-theme/dist/select2-bootstrap.css";

@import "cabecalho";
@import "rodape";
@import "login";
@import "sobre";
@import "home";
@import "produtos";
@import "produto";
@import "servicos";
@import "fornecedores";
@import "patrocinadores";
@import "clientes";
@import "equipe";
@import "timeline";

::selection {
    color: #fff;
}

.container{
    @media(min-width: 1600px){
        max-width: 1400px;
    }
}

.section-breadcrumb {
    padding: 30px 0px;
    background-color: #F5F5F5;

    a {
        font-size: 15px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
        color: #5F5F5F !important;
        font-family: "Poppins", sans-serif;

        &:hover, &:focus {
            text-decoration: none !important;
        }
    }

    li {
        color: #5F5F5F !important;
        font-weight: 300;

        span {
            color: #5F5F5F !important;
        }
    }
}

.pagination {
    margin-bottom: 0px;

    @media(max-width: 991px){
        li.page-item {
            display: none;
        }

        .page-item:nth-child( 1 ),
        .page-item:nth-child( 2 ),
        .page-item:nth-child( 3 ),
        .page-item:nth-last-child( 2 ),
        .page-item:last-child,
        .page-item.active {
            display: block;
        }
    }

    .page-item {
        .page-link {
            width: 40px;
            height: 40px;
            color: #111431;
            background-color: rgba(240, 240, 240, 0.74);
            border: 1px solid #111431;
            transition: all 0.5s ease;
            margin: 0px 2.5px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;

            &:hover, &:focus{
                color: #fff;
                transition: all 0.5s ease;
            }
        }
    }

    .active {
        .page-link {
            color: #fff;
            margin: 0px 2.5px;
            width: 40px;
            height: 40px;
            transition: all 0.5s ease;
            font-family: "Poppins", sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
        }
    }

    .disabled{
        cursor: not-allowed;
    }
}


::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 100px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}

.container-1400{
    @media(min-width: 1600px){
        max-width: 1400px;
    }
}

.ml-30-alta-resolucao{
    margin-left: 30px;

    @media(max-width: 991px){
        margin-left: 0px;
    }
}

.main{
    background-color: #E5E5E5;
}

.section-title-padrao{
    .title-padrao{
        padding: 3.1rem 0rem;

        .detalhe-amarelo{
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin: 0px auto;
            margin-bottom: 1rem;
        }

        .content-title{
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;

            span{
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }
}

.section-dados-sobre-quem-somos{
    padding: 3rem 0rem;
    background-color: #F5F5F5;

    .box-item-dado{
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        height: 183px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 0.5px #33333310 solid;
        position: relative;
        will-change: transform;
        transition: all 0.5s ease;

        &:hover, &:focus{
            transition: all 0.5s ease;
            transform: scale(1.02);

            .detalhe-vermelho{
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        .detalhe-vermelho{
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            transition: all 0.5s ease;

            img{
                width: 17.04px;
                height: 17.25px;
            }
        }

        .imagem-dado{
            text-align: center;
            margin-bottom: 0.75rem;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;

            img{
                width: 56px;
            }
        }

        .texto-dado{
            font-family: "Poppins", sans-serif;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span{
                font-family: "Poppins", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #111431;
            }
        }
    }
}

.titulo-padrao-individual{
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #111431;
    margin: 35px 0rem 65px 0rem;
    position: relative;
    width: fit-content;

    @media(max-width: 991px){
        margin: 0px 0px 30px 0px;
    }

    &::before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        border-radius: 2.66667px;
    }
}

.titulo-padrao-individual-secundario{
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #111431;
    margin: 0rem 0rem 3rem 0rem;
    position: relative;
    width: fit-content;

    &::before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        border-radius: 2.66667px;
    }
}

.titulo-padrao-individual{
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #111431;
    margin: 35px 0rem 65px 0rem;
    position: relative;
    width: fit-content;

    @media(max-width: 991px){
        margin: 0px 0px 30px 0px;
    }

    &::before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        border-radius: 2.66667px;
    }
}

.titulo-padrao-individual-secundario{
    font-family: "Poppins", sans-serif;
    font-size: 25px;
    font-style: normal;
    font-weight: 300;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: center;
    color: #111431;
    margin: 0rem 0rem 3rem 0rem;
    position: relative;
    width: fit-content;

    &::before{
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        border-radius: 2.66667px;
    }
}

.section-politica{
    font-family: "Poppins", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: justify;
    color: #111431;
    padding: 65px 0px;

    @media(max-width: 991px){
        padding: 30px 0px;
    }
}

.contrastFilterLogo{
    filter: brightness(0) invert(1);
}

.box-conteudo {

    @media(max-width: 991px){
        text-align: center;
    }

    .detalhe {
        border-radius: 2.66667px;
        width: 24px;
        height: 8px;
        margin-bottom: 1rem;
        margin-right: auto;

        @media(max-width: 991px){
            text-align: center;
            margin-left: auto;
            margin-top: 30px;
        }
    }

    .title-conteudo {
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: 47px;
        letter-spacing: 0em;
        text-align: left;
        color: #333333;
        position: relative;
        font-family: 'Poppins', sans-serif;

        @media(max-width: 991px) {
            text-align: center;
        }
    }

    .solicite-orcamento {
        margin-top: 30px;

        @media(max-width: 991px) {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        a {
            font-size: 18px;
            font-style: normal;
            font-weight: 300;
            line-height: 21px;
            letter-spacing: 0em;
            text-align: left;
            border-radius: 6px;
            padding: 5px 32px;
            transition: all 0.5s ease;

            &:hover,
            &:focus {
                text-decoration: none !important;
                transition: all 0.5s ease;
            }
        }
    }

    .descricao {
        max-height: 60px;
        overflow: hidden;
        margin-bottom: 5px !important;
        transition: all 0.5s ease;
    }

    .mais-informacoes {
        color: #000;
        transition: all 0.5s ease;
        margin-bottom: 30px;

        &:hover,
        &:focus {
            transition: all 0.5s ease;
            text-decoration: none !important;
        }
    }

    .description-conteudo {
        font-family: 'Poppins', sans-serif;
        font-size: 17px;
        font-style: normal;
        font-weight: 300;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: #000;
        margin: 30px 0px;
        transition: all 0.5s ease;

        span {
            font-weight: 400;
        }

        @media(max-width: 991px) {
            text-align: center;
        }
    }
}

.prev-fotos-conteudo {
    cursor: pointer;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100%;
    background-color: #F5F5F5;
    transition: all 0.5s ease;
    transform: rotate(90deg);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 15px;

    &:hover, &:focus {
    }

    i{
        font-size: 20px;
    }
}

.next-fotos-conteudo {
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-top: 15px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100%;
    background-color: #F5F5F5;
    transition: all 0.5s ease;
    transform: rotate(90deg);
    margin-left: auto;
    margin-right: auto;

    &:hover, &:focus {
    }

   i{
        font-size: 20px;
    }
}

.imagem-foto-conteudo {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 65%;
    cursor: pointer;
    transition: all 0.5s ease;
    border: 1px #fff solid;
    margin-bottom: 15px;

    @media(max-width: 991px) {
        padding-top: 200px;
        background-size: contain;
    }

    &:hover,
    &:focus {
        transition: all 0.5s ease;
    }
}

.foto-destaque-conteudo {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-top: 125%;
    cursor: pointer;

    @media(max-width: 991px) {
        margin-top: 30px;
    }
}

.ampliar {
    position: absolute;
    bottom: 15px;
    right: 30px;
    width: 39px;
    transition: all 0.5s ease;
    will-change: transform;
    cursor: pointer;

    &:hover,
    &:focus {
        transform: scale(1.02);
        transition: all 0.5s ease;
    }
}

.cor-fundo-modal {
    background-color: #3b0000;
    border-radius: 6px;
}

.box-contact-orcamento {
    margin-top: 30px;
    font-family: 'Poppins', sans-serif !important;

    .aguarde-contato {
        color: #fff;
        font-weight: 200;
        text-align: center;
        font-size: 22px;
        line-height: 22px;

        b {
            font-weight: 700;
        }
    }

    .form-group-contact {
        margin-bottom: 10px !important;

        label {
            text-align: left;
            color: #fff;
            font-weight: 500;
            width: 100%;
        }
    }

    .form-contact {
        font-size: 12px;
        line-height: 12px;
        border-radius: 0px;
        font-weight: 500;
        border: none !important;
        color: #888888;
        height: 35px;

        &::placeholder {
            color: #c4c4c4;
            letter-spacing: 0px;
        }
    }

    .btn-contact {
        text-align: center;
        transition: all 0.5s ease;
        width: 100%;
        font-weight: 500;
        padding: 12px 0px;
        margin-top: 7px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 1px;
        border-radius: 0.25rem;
        max-width: 304px;

        @media(min-width: 1200px) {
            margin-left: 10px;
        }

        &:hover,
        &:focus {
            transition: all 0.5s ease;
        }
    }
}

.carousel-fotos-xs {
    position: relative;

    .foto-destaque-padrao {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        padding-top: 350px;
        cursor: pointer;
        position: relative;
    }

    .prev-fotos-padrao {
        position: absolute;
        top: calc(50% + 50px);
        left: 0px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #fff;
        transition: all 0.5s ease;

        @media(max-width: 767px) {
            width: 30px;
            height: 30px;
            left: 15px;
            top: calc(50%);

            i {
                font-size: 20px !important;
            }
        }

        &:hover,
        &:focus {
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }

    .next-fotos-padrao {
        position: absolute;
        top: calc(50% + 50px);
        right: 0px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #fff;
        transition: all 0.5s ease;

        @media(max-width: 767px) {
            width: 30px;
            height: 30px;
            right: 15px;
            top: calc(50%);

            i {
                font-size: 20px !important;
            }
        }

        &:hover,
        &:focus {
            transition: all 0.5s ease;
        }

        i {
            font-size: 27px;
        }
    }
}

.redes-sociais-sobre{
    margin-top: 30px;

    @media(max-width: 991px){
        margin-bottom: 30px;
        text-align: center;

        a{
            img{
                margin: 0px 5px;
            }
        }
    }

    a{
        transition: all 0.5s ease;

        img{
            will-change: transform;
            width: 26px;
            margin-right: 5px;
            transition: all 0.5s ease;

            &:hover, &:focus{
                transition: all 0.5s ease;
                transform: translateY(-1px);
            }
        }

        &:hover, &:focus{
            transition: all 0.5s ease;
            text-decoration: none;
        }
    }
}
