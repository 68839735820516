// .tns-outer {
//     display: flex;
//     align-items: flex-end;
// }

.imagem {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

    @media (max-width: 991px) {
        height: 300px !important;
    }
}

.prev-banner {
    position: absolute;
    top: calc(50% - 25px);
    left: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100%;
    color: #f5f5f5;
    transition: all 0.5s ease;

    @media (max-width: 991px) {
        width: 30px;
        height: 30px;
        left: 3px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        left: -10px;
    }

    @media (min-width: 1200px) and (max-width: 1365px) {
        left: -15px;
    }

    &:hover,
    &:focus {}

    i {
        font-size: 30px;
    }
}

.next-banner {
    position: absolute;
    top: calc(50% - 25px);
    right: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    vertical-align: middle;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 100%;
    color: #f5f5f5;
    transition: all 0.5s ease;

    @media (max-width: 991px) {
        width: 30px;
        height: 30px;
        right: 3px;
    }

    @media (min-width: 992px) and (max-width: 1199px) {
        right: -10px;
    }

    @media (min-width: 1200px) and (max-width: 1365px) {
        right: -15px;
    }

    &:hover,
    &:focus {}

    i {
        font-size: 30px;
    }
}

.container-banner {
    position: relative;



    .mascara-vermelha {
        position: absolute;
        opacity: 0.9;
        left: 0;
        top: 0;
        bottom: 0;
        z-index: 1;
        width: 45%;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    .detalhe-amarelo {
        width: 8px;
        right: 0px;
        z-index: 4;
        left: calc(45% - 4px);
        height: 319px;
        top: calc(50% - 159.5px);
        position: absolute;

        @media (max-width: 991px) {
            display: none;
        }
    }

    .descricao-banner {
        position: absolute;
        top: calc(50% - 72px);
        left: 5rem;
        font-family: "Poppins", sans-serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        width: 50%;
        overflow: visible;
        opacity: 0;
        transform: translateY(80px);
        transition: all 0.5s ease;
        color: #fff;
        z-index: 2;

        span {
            font-family: "Poppins", sans-serif;
            font-size: 44px;
            font-style: normal;
            font-weight: 700;
            line-height: 67px;
            letter-spacing: 0em;
            text-align: left;
            color: #fff;
        }

        @media (max-width: 991px) {
            height: auto;
            font-size: 25px !important;
            width: 100%;
            line-height: 28px;
            text-align: center;
            padding-top: 50px;
            padding-bottom: 50px;
            left: 0;
            font-size: 25px;
            top: calc(50% - 130px);
            padding-left: 30px;
            padding-right: 30px;
            max-height: 250px;
            overflow: hidden !important;

            span {
                font-size: 35px;
                line-height: 38px;
            }
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            width: 42%;
            left: 1rem;
        }

        @media (min-width: 1200px) and (max-width: 1599px) {
            left: 3rem;
            width: 40%;
            padding-left: 5rem;
        }

        @media (min-width: 1600px) {
            left: 5rem;
            width: 38%;
            padding-left: 8rem;
        }

        .link-descricao {
            margin-top: 30px;

            a {
                border-radius: 6px;
                font-family: "Poppins", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: 23px;
                letter-spacing: 0em;
                padding: 0.85rem 1.44rem;
                transition: all 0.5s ease;
                display: flex;
                align-items: center;
                justify-content: center;
                width: fit-content;

                @media (max-width: 991px) {
                    margin: 0 auto;
                }

                &:hover,
                &:focus {
                    transition: all 0.5s ease;
                }

                i {
                    font-size: 25px;
                    margin-left: 5px;
                    transition: all 0.5s ease;
                }
            }
        }
    }



    .tns-nav {
        position: absolute;
        left: 50%;
        bottom: 2% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        z-index: 1;

        @media (max-width: 991px) {
            width: 100%;
            bottom: 5%;
            flex-direction: row;
            left: 0px;
            right: 0px;
        }

        button {
            width: 17.76px;
            height: 17.76px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #fff;
            border: 1px #fff solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            border: 1px #fff solid !important;

            &:before {
                content: "";
                width: 5.63px;
                height: 5.63px;
                border-radius: 100%;
                background-color: #fff;
                left: calc(50% - 2.815px);
                top: calc(50% - 2.815px);
                position: absolute;
            }
        }
    }
}

.section-categorias-produtos {
    margin-top: -3rem;
    background-color: #f5f5f5;

    @media (max-width: 991px) {
        margin-top: 1.5rem;
    }

    .box-categoria-produto {
        transition: all 0.5s ease;
        will-change: transform;
        background-color: #fff;
        padding: 30px;
        position: relative;
        box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.07);
        z-index: 1;

        @media (max-width: 991px) {
            margin-bottom: 30px;
        }

        &:hover,
        &:focus {
            transform: scale(1.02);
            transition: all 0.5s ease;
            z-index: 2;

            .ver-mais-categoria {
                color: #111431;
                transition: all 0.5s ease;

                .img-categoria-ver-mais {
                    transition: all 0.5s ease;
                    display: none;
                }

                .img-categoria-ver-mais-2 {
                    transition: all 0.5s ease;
                    display: inline-block;
                }
            }

            .detalhe-vermelho {
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        .imagem-categoria {
            text-align: center;
            transition: all 0.5s ease;

            img {
                max-width: 100px;
            }
        }

        .title-categoria {
            font-family: "Poppins", sans-serif;
            font-size: 23px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;
            margin: 1rem 0rem;

            @media (min-width: 992px) and (max-width: 1199px) {
                height: 70px;
                display: flex;
                align-items: center;
                justify-content: center;
                overflow: hidden;
            }
        }

        .ver-mais-categoria {
            margin-top: 0.5rem;
            transition: all 0.5s ease;
            font-family: "Poppins", sans-serif;
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0em;
            text-align: center;

            .img-categoria-ver-mais {
                margin-left: 5px;
                width: 23px;
                transition: all 0.5s ease;
                will-change: transform;
            }

            .img-categoria-ver-mais-2 {
                margin-left: 5px;
                width: 23px;
                display: none;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }

        .detalhe-vermelho {
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            transition: all 0.5s ease;

            img {
                width: 17.04px;
                height: 17.25px;
            }
        }
    }

    .prev-categorias {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        &:hover,
        &:focus {
            color: #fff;
            transition: all 0.5s ease;
        }

        i {
            font-size: 20px;
            transition: all 0.5s ease;
        }
    }

    .next-categorias {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        &:hover,
        &:focus {
            transition: all 0.5s ease;
        }

        i {
            font-size: 20px;
            transition: all 0.5s ease;
        }
    }
}

.section-produtos-home {
    background-color: #f5f5f5;

    .title-section {
        padding: 65px 0rem;

        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .card-produto {
        border: 1px #e6e6e6 solid;
        position: relative;
        transition: all 0.5s ease;

        &:hover,
        &:focus {
            .box-absolute-produto {
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-categoria {
                margin-top: 0.5rem;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-produto {
                margin-bottom: 1.5rem;
                transition: all 0.5s ease;
            }

            .box-foto-produto {
                .foto-produto {
                    transform: scale(1.02);
                }
            }
        }

        .box-absolute-produto {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            position: absolute;
            width: 100%;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 0px;
            opacity: 0;
            transition: all 0.5s ease;

            @media (max-width: 991px) {
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            i {
                font-size: 23px;
                margin-left: 5px;
                margin-top: 2px;
            }
        }

        .box-foto-produto {
            padding: 1rem;
            background-color: #fff;

            .foto-produto {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 16.69rem;
                will-change: transform;
                transition: all 0.5s ease;
            }
        }

        .conteudo-produto {
            padding: 30px;
            background-color: #fff;

            .barra-cinza {
                border-top: 1px #e6e6e6 solid;
            }

            .titulo-categoria {
                font-family: "Poppins", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                color: #737373;
                margin: 30px 0rem 0.5rem 0rem;
                transition: all 0.5s ease;

                @media (max-width: 991px) {
                    margin-top: 0.5rem;
                    transition: all 0.5s ease;
                }
            }

            .titulo-produto {
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111431;
                transition: all 0.5s ease;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 991px) {
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-produtos {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .next-produtos {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .ver-mais-produtos {
        margin-top: 4.9rem;
        text-align: center;
        z-index: 1;

        a {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            border-radius: 6px;
            padding: 0.85rem 2.2rem;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;

            i {
                font-size: 25px;
                margin-left: 5px;
            }
        }
    }
}

.section-servicos-home {
    background-color: #f5f5f5;

    .title-section {
        padding: 65px 0rem;

        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .card-servico {
        border: 1px #e6e6e6 solid;
        position: relative;
        transition: all 0.5s ease;

        &:hover,
        &:focus {
            .box-absolute-servico {
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            .conteudo-servico .titulo-categoria {
                margin-top: 0.5rem;
                transition: all 0.5s ease;
            }

            .conteudo-servico .titulo-servico {
                margin-bottom: 1.5rem;
                transition: all 0.5s ease;
            }

            .box-foto-servico {
                .foto-servico {
                    transform: scale(1.02);
                }
            }
        }

        .box-absolute-servico {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            position: absolute;
            width: 100%;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 0px;
            opacity: 0;
            transition: all 0.5s ease;

            @media (max-width: 991px) {
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            i {
                font-size: 23px;
                margin-left: 5px;
                margin-top: 2px;
            }
        }

        .box-foto-servico {
            padding: 1rem;
            background-color: #fff;

            .foto-servico {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 16.69rem;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }

        .conteudo-servico {
            padding: 30px;
            background-color: #fff;

            .barra-cinza {
                border-top: 1px #e6e6e6 solid;
            }

            .titulo-categoria {
                font-family: "Poppins", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                color: #737373;
                margin: 30px 0rem 0.5rem 0rem;
                transition: all 0.5s ease;

                @media (max-width: 991px) {
                    margin-top: 0.5rem;
                    transition: all 0.5s ease;
                }
            }

            .titulo-servico {
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111431;
                transition: all 0.5s ease;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 991px) {
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-servicos {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .next-servicos {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .ver-mais-servicos {
        margin-top: 4.9rem;
        text-align: center;
        z-index: 1;

        a {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            border-radius: 6px;
            padding: 0.85rem 2.2rem;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;

            i {
                font-size: 25px;
                margin-left: 5px;
            }
        }
    }
}

.section-equipe-home {
    background-color: #f5f5f5;

    .title-section {
        padding: 65px 0rem;

        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .card-equipe {
        border: 1px #e6e6e6 solid;
        position: relative;
        transition: all 0.5s ease;

        &:hover {
            .box-foto-equipe {
                .foto-equipe {
                    transform: scale(1.02);
                }
            }
        }

        .box-foto-equipe {
            padding: 1rem;
            background-color: #fff;

            .foto-equipe {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 16.69rem;
                transition: all 0.5s ease;
                will-change: transform;
            }
        }

        .conteudo-equipe {
            padding: 30px;
            background-color: #fff;

            .barra-cinza {
                border-top: 1px #e6e6e6 solid;
            }

            .titulo-equipe {
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111431;
                transition: all 0.5s ease;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                will-change: transform;

                @media (max-width: 991px) {
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-equipe {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .next-equipe {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .ver-mais-equipe {
        margin-top: 4.9rem;
        text-align: center;
        z-index: 1;

        a {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            border-radius: 6px;
            padding: 0.85rem 2.2rem;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;

            i {
                font-size: 25px;
                margin-left: 5px;
            }
        }
    }
}

.section-sobre-carousel {
    background-color: #f5f5f5;
}

.section-sobre-home {
    background-color: #f5f5f5;
    padding: 65px 0rem 0rem 0rem;

    .box-sobre-home {
        .conteudo-sobre-home {
            padding: 5rem 30px 5rem 12rem;

            @media (max-width: 991px) {
                padding: 5rem 30px;
            }

            @media (min-width: 992px) and (max-width: 1365px) {
                padding: 5rem 30px 5rem 5rem;
            }

            .detalhe-amarelo {
                width: 24px;
                height: 8px;
                border-radius: 2.66667px;
            }

            .titulo-sobre-home {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 300;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                margin-top: 1rem;

                span {
                    font-family: "Poppins", sans-serif;
                    font-size: 34px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 41px;
                    letter-spacing: 0em;
                    text-align: left;
                }
            }

            .texto-sobre-home {
                font-family: "Poppins", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 33px;
                letter-spacing: 0em;
                text-align: left;
                color: #fff;
                margin: 1.5rem 0rem 2.8rem 0rem;
                width: 100%;
                height: 200px;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .ver-mais-sobre-home {
                a {
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    border-radius: 6px;
                    padding: 0.85rem 2.2rem;
                    transition: all 0.5s ease;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;

                    i {
                        font-size: 25px;
                        margin-left: 5px;
                    }
                }
            }

            .ver-mais-sobre-home-no {
                a {
                    font-family: "Poppins", sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 23px;
                    letter-spacing: 0em;
                    border-radius: 6px;
                    padding: 0.85rem 2.2rem;
                    transition: all 0.5s ease;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;

                    i {
                        font-size: 25px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .foto-sobre-home {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 100%;

        @media (max-width: 991px) {
            padding-top: 20rem;
        }
    }

        .foto-sobre-home2 {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        height: 70%;
        margin-top: 85px;

        @media (max-width: 991px) {
            padding-top: 20rem;
            margin-top: 0;
        }
    }
}

.section-dados-sobre {
    background-color: #f5f5f5;
    margin-top: -130px;

    @media (max-width: 991px) {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    @media (min-width: 992px) and (max-width: 1599px) {
        margin-top: -105px;
    }

    .box-item-dado {
        background-color: #fff;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        height: 183px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 0.5px #33333310 solid;
        position: relative;
        transition: all 0.5s ease;
        will-change: transform;

        &:hover,
        &:focus {
            transform: scale(1.02);
            transition: all 0.5s ease;

            .detalhe-vermelho {
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        .detalhe-vermelho {
            position: absolute;
            right: 15px;
            bottom: 15px;
            opacity: 0;
            transition: all 0.5s ease;

            img {
                width: 17.04px;
                height: 17.25px;
            }
        }

        .imagem-dado {
            text-align: center;
            margin-bottom: 0.75rem;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 56px;
            }
        }

        .texto-dado {
            font-family: "Poppins", sans-serif;
            font-size: 25px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 20px;
                font-style: normal;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: center;
                color: #111431;
            }
        }
    }
}

.section-fornecedores-home {
    background-color: #f5f5f5;
    padding-bottom: 30px;

    .title-section {
        padding: 65px 0rem;

        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .box-logo-fornecedor {
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 2.3rem;
        transition: all 0.5s ease;
        will-change: transform;
        overflow: hidden;
        position: relative;

        .absoluteBar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0px;
            padding-left: 15px;
            padding-right: 15px;
            opacity: 0;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23px;
            will-change: transform;
            overflow: hidden;
        }

        &:hover,
        &:focus {
            transform: scale(1.02);
            transition: all 0.5s ease;

            .absoluteBar {
                height: 55px;
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        @media (max-width: 991px) {
            margin: 0px 3rem;
        }

        img {
            max-height: 100%;
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        @media (max-width: 991px) {
            display: none !important;
        }

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-fornecedores {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .next-fornecedores {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }
}

.section-clientes-home {
    background-color: #f5f5f5;
    padding-bottom: 30px;

    .title-section {
        padding: 65px 0rem;

        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .box-logo-cliente {
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 2.3rem;
        transition: all 0.5s ease;
        will-change: transform;
        overflow: hidden;
        position: relative;

        .absoluteBar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0px;
            padding-left: 15px;
            padding-right: 15px;
            opacity: 0;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23px;
            will-change: transform;
            overflow: hidden;
        }

        &:hover,
        &:focus {
            transform: scale(1.02);
            transition: all 0.5s ease;

            .absoluteBar {
                height: 55px;
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        @media (max-width: 991px) {
            margin: 0px 3rem;
        }

        img {
            max-height: 100%;
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        @media (max-width: 991px) {
            display: none !important;
        }

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-clientes {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .next-clientes {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }
}

.section-patrocinadores-home {
    background-color: #f5f5f5;
    padding-bottom: 30px;

    .title-section {
        padding: 65px 0rem;

        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .box-logo-patrocinador {
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 2.3rem;
        transition: all 0.5s ease;
        will-change: transform;
        overflow: hidden;
        position: relative;

        .absoluteBar {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0px;
            padding-left: 15px;
            padding-right: 15px;
            opacity: 0;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23px;
            will-change: transform;
            overflow: hidden;
        }

        &:hover,
        &:focus {
            transform: scale(1.02);
            transition: all 0.5s ease;

            .absoluteBar {
                height: 55px;
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        @media (max-width: 991px) {
            margin: 0px 3rem;
        }

        img {
            max-height: 100%;
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        @media (max-width: 991px) {
            display: none !important;
        }

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-patrocinadores {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .next-patrocinadores {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }
}

.section-portfolios-home {
    background-color: #f5f5f5;

    .title-section {
        padding: 65px 0rem;

        .detalhe {
            border-radius: 2.66667px;
            width: 24px;
            height: 8px;
            margin-bottom: 1rem;
            margin-left: auto;
            margin-right: auto;
        }

        .content-title {
            font-family: "Poppins", sans-serif;
            font-size: 34px;
            font-style: normal;
            font-weight: 300;
            line-height: 41px;
            letter-spacing: 0em;
            text-align: center;
            color: #111431;

            span {
                font-family: "Poppins", sans-serif;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 41px;
                letter-spacing: 0em;
                text-align: center;
            }
        }
    }

    .card-produto {
        border: 1px #e6e6e6 solid;
        position: relative;
        transition: all 0.5s ease;

        &:hover,
        &:focus {
            .box-absolute-produto {
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-categoria {
                margin-top: 0.5rem;
                transition: all 0.5s ease;
            }

            .conteudo-produto .titulo-produto {
                margin-bottom: 1.5rem;
                transition: all 0.5s ease;
            }

            .box-foto-produto {
                .foto-produto {
                    transform: scale(1.02);
                }
            }
        }

        .box-absolute-produto {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            text-align: center;
            position: absolute;
            width: 100%;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 0px;
            opacity: 0;
            transition: all 0.5s ease;

            @media (max-width: 991px) {
                height: 45px;
                opacity: 1;
                transition: all 0.5s ease;
            }

            i {
                font-size: 23px;
                margin-left: 5px;
                margin-top: 2px;
            }
        }

        .box-foto-produto {
            padding: 1rem;
            background-color: #fff;

            .foto-produto {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding-top: 16.69rem;
                will-change: transform;
                transition: all 0.5s ease;
            }
        }

        .conteudo-produto {
            padding: 30px;
            background-color: #fff;

            .barra-cinza {
                border-top: 1px #e6e6e6 solid;
            }

            .titulo-categoria {
                font-family: "Poppins", sans-serif;
                font-size: 15px;
                font-style: normal;
                font-weight: 300;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: center;
                color: #737373;
                margin: 30px 0rem 0.5rem 0rem;
                transition: all 0.5s ease;

                @media (max-width: 991px) {
                    margin-top: 0.5rem;
                    transition: all 0.5s ease;
                }
            }

            .titulo-produto {
                font-family: "Poppins";
                font-size: 15px;
                font-style: normal;
                font-weight: 500;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;
                color: #111431;
                transition: all 0.5s ease;
                height: 46px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 991px) {
                    margin-bottom: 1.5rem;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    .tns-nav {
        position: absolute;
        bottom: -45px;
        width: 100%;
        text-align: center;
        z-index: 1;
        left: 0px;

        button {
            width: 8px;
            height: 8px;
            border: none !important;
            padding: 0px !important;
            transition: all 0.5s ease;
            border-radius: 50%;
            background-color: #111431;
            border: 1px #111431 solid !important;
            margin: 0.25rem;
            position: relative;

            &:hover,
            &:focus {
                transition: all 0.5s ease;
            }
        }

        .tns-nav-active {
            background-color: transparent;
            width: 19.2px;
            height: 8px;
            border-radius: 2.66667px;
        }
    }

    .prev-portfolios {
        position: absolute;
        top: calc(50% - 25px);
        left: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            left: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            left: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            left: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .next-portfolios {
        position: absolute;
        top: calc(50% - 25px);
        right: -45px;
        cursor: pointer;
        width: 50px;
        height: 50px;
        vertical-align: middle;
        justify-content: center;
        align-items: center;
        display: flex;
        border-radius: 100%;
        background-color: #f5f5f5;
        transition: all 0.5s ease;

        @media (max-width: 991px) {
            width: 30px;
            height: 30px;
            right: 3px;
        }

        @media (min-width: 992px) and (max-width: 1199px) {
            right: -10px;
        }

        @media (min-width: 1200px) and (max-width: 1365px) {
            right: -15px;
        }

        &:hover,
        &:focus {}

        i {
            font-size: 30px;
        }
    }

    .ver-mais-produtos {
        margin-top: 4.9rem;
        text-align: center;
        z-index: 1;

        a {
            font-family: "Poppins", sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            letter-spacing: 0em;
            border-radius: 6px;
            padding: 0.85rem 2.2rem;
            transition: all 0.5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            margin: 0 auto;

            i {
                font-size: 25px;
                margin-left: 5px;
            }
        }
    }
}

