.section-clientes-individual{
    padding: 35px 0px 30px 0px;
    background-color: #F5F5F5;

    @media(max-width: 991px){
        padding: 0px 0px 30px 0px;
    }

    .box-logo-cliente{
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        padding: 1.5rem;
        transition: all 0.5s ease;
        will-change: transform;
        overflow: hidden;
        margin-bottom: 2.5rem;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
        position: relative;

        .absoluteBar{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0px;
            padding-left: 15px;
            padding-right: 15px;
            opacity: 0;
            transition: all 0.5s ease;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: "Poppins",sans-serif;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            letter-spacing: 0;
            line-height: 23px;
            will-change: transform;
            overflow: hidden;
        }

        &:hover,
        &:focus {
            transform: scale(1.02);
            transition: all 0.5s ease;

            .absoluteBar{
                height: 55px;
                opacity: 1;
                transition: all 0.5s ease;
            }
        }

        @media(max-width: 991px){
            padding: 30px;
            margin-left: 30px;
            margin-right: 30px;
        }

        @media(min-width: 992px) and (max-width: 1599px){
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 30px;
        }

        img{
            max-height: 100%;
        }
    }
}
